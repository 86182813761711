
/** @todo transform into enum */
export const PART_TYPES = [
    "Acessórios", // 1
    "Combustível", // 2
    "Lubrificantes", // 3
    "Peças", // 4
    "Outros", // 5
    "Tóxico", // 6 
    "Industrializado" // 7
]